import React, { lazy, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Layout from "./components/Layout";
import Homepage from "./pages/Homepage";
import About_us from "./pages/About_us";
import Blogs from "./pages/Blogs";
import Contact_us from "./pages/Contact-us";
import Gallery from "./pages/Galleries";
import Packages from "./pages/Packages";
import Privacy_policy from "./pages/Privacy_policy";
import Services from "./pages/Services";
import Terms_conditions from "./pages/Terms_conditions";
import Book_service from "./pages/Book_service";
import ScrollToTop from "./components/ScrollToTop";
import Repair_car_quote from "./pages/Repair_car_quote";

import Myaccount from "./pages/Myaccount";
import Login from "./pages/Login";
import Reset_password from "./pages/Reset_password";
import PrivateRoute from "./components/ProtectedRoutes";
import LoginPrivateRoute from "./components/LoginPrivateRoute";
import Booking_view from "./pages/Booking_view";
import Booking_list from "./pages/Booking_list";
import { checkAuth } from "./redux/features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getMetaData,
  getStaticData,
} from "./redux/features/cms/staticDataSlice";
import Loader from "./components/loader/Loader";
import { Helmet } from "react-helmet";
import Payment_getway from "./pages/Payment_getway";
import Offers from "./pages/Offers";
import ViewBlog from "./pages/ViewBlog";
import Service_view from "./pages/Service_view";
import { setLanguage } from "./config/Constants";
import Change_password from "./pages/Change_password";
import { LanguageProvider } from "./components/LanguageProvider";
import Payment_success from "./pages/Payment_success";
import Landing_page from "./pages/Landing_page";
import Thankyou from "./pages/Thankyou";
import NotFound from "./pages/NotFound/NotFound";

const Country = lazy(() => import("./pages/Country"));

function App() {
  const disptach = useDispatch();
  const [meta_data, setMedata_data] = useState([]);
  useEffect(() => {
    disptach(checkAuth())
      .then((res) => {
        if(res.payload.success){
          localStorage.clear();
          window.location.reload();
        }
      })
      .catch((error) => {
        
      });
  }, []);


  const static_data = JSON.parse(
    JSON.stringify(useSelector((state) => state.staticData))
  );
  if (static_data.loading == "PENDING") {
    return <Loader />;
  }

  console.clear();

  return (
    <div className="App">
            <ToastContainer />

      <Router>
        <ScrollToTop />
        <LanguageProvider>
        <Layout {...static_data.data}>
          <Routes>
            <Route path="/" element={<Country />} />
            <Route path="/home" element={<Homepage />} />
            <Route exact path="/about-us" element={<About_us />} />
            <Route exact path="/blogs" element={<Blogs />} />
            <Route exact path="/contact-us" element={<Contact_us />} />
            <Route exact path="/gallery" element={<Gallery />} />
            {/* <Route exact path="/packages" element={<Packages />} /> */}
            <Route exact path="/offers" element={<Offers />} />
            <Route exact path="/privacy-policy" element={<Privacy_policy />} />
            <Route exact path="/payment/:status" element={<Payment_success />} />
            <Route exact path="/thankyou" element={<Thankyou />} />
            <Route exact path="landing-page/:language/:campaign/:source" element={<Landing_page />} />
            <Route exact path="/blogs/:id" element={<ViewBlog />} />
            <Route
              exact
              path="terms-and-conditions"
              element={<Terms_conditions />}
            />
            <Route
              exact
              path="repair-car-quote"
              element={<Repair_car_quote />}
            />

            {/* services routes */}
            <Route exact path="services" element={<Services />} />
            <Route exact path="services/:slug" element={<Service_view />} />
            <Route path="page-not-found" element={<NotFound />} />
            <Route path="*" element={<Navigate to="/page-not-found" replace />} />


            {/* myaccoutn routes */}
            {/* <Route path="/profile">
              <Route
                path=""
                element={
                  <PrivateRoute>
                    <Myaccount />
                  </PrivateRoute>
                }
              />
            </Route>

            <Route path="/change-password">
              <Route
                path=""
                element={
                  <PrivateRoute>
                    <Change_password />
                  </PrivateRoute>
                }
              />
            </Route>

            <Route path="/payment-getway">
              <Route
                path=""
                element={
                  <PrivateRoute>
                    <Payment_getway />
                  </PrivateRoute>
                }
              />
            </Route>

            <Route path="/booking-service/:id">
              <Route
                path=""
                element={
                  <PrivateRoute>
                    <Book_service />
                  </PrivateRoute>
                }
              />
            </Route>

            <Route path="/booking-list">
              <Route
                path=""
                element={
                  <PrivateRoute>
                    <Booking_list />
                  </PrivateRoute>
                }
              />
            </Route>

            <Route path="/booking-view/:id">
              <Route
                path=""
                element={
                  <PrivateRoute>
                    <Booking_view />
                  </PrivateRoute>
                }
              />
            </Route> */}

            {/* if user logged in  redirect to profile */}
            {/* <Route path="/auth">
              <Route
                path=""
                element={
                  <LoginPrivateRoute>
                    <Login />
                  </LoginPrivateRoute>
                }
              />
            </Route>

            <Route path="/reset-password">
              <Route
                path=""
                element={
                  <LoginPrivateRoute>
                    <Reset_password />
                  </LoginPrivateRoute>
                }
              />
            </Route> */}
            {/* end  */}
          </Routes>
        </Layout>
        </LanguageProvider>
      </Router>
    </div>
  );
}

export default App;
