import React, { useEffect, useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation} from "react-router-dom";
import Loader from "../components/loader/Loader";
import {
  getBlog,
  getPopularBlog,
  getSingleBlog,
} from "../redux/features/cms/blogSlice";
import { ASSETS } from "../config/Constants";
import parse from "html-react-parser";
import { LanguageContext } from "../components/LanguageProvider";
import { Helmet } from 'react-helmet';

export default function ViewBlog() {
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [loading, setLoading] = useState(false);
  const [allBlogs, setAllBlogs] = useState([]);
  const location = useLocation();
  const [popularBlogs, setPopularBlogs] = useState([]);

  const pathname = location.pathname;
  const lastPathSegment = pathname.split("/").filter(Boolean).pop();
  const dispatch = useDispatch();
  const { selectedLanguage } = useContext(LanguageContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [singleBlogResponse, blogResponse, popularBlogResponse] =
          await Promise.all([
            dispatch(getSingleBlog(lastPathSegment)),
            dispatch(getBlog()),
            dispatch(getPopularBlog()),
          ]);
        setSelectedBlog(singleBlogResponse.payload.data.blogs);
        setAllBlogs(blogResponse.payload.data.blogs);
        setPopularBlogs(popularBlogResponse.payload.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, lastPathSegment]);

  if (
    loading ||
    !selectedBlog ||
    !allBlogs ||
    (popularBlogs && popularBlogs.length === 0)
  ) {
    return <Loader />;
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };
  
  return (
    <>
       <Helmet>
        <title>{selectedBlog &&selectedBlog.meta_title}</title>
        <meta name="title" content={selectedBlog &&selectedBlog.meta_title} />
        <link rel="canonical" href={selectedBlog &&selectedBlog.canonical_url} />
        <meta name="description" content={selectedBlog &&selectedBlog.meta_description} />
        <meta name="keywords" content={selectedBlog &&selectedBlog.meta_keywords} />
        <meta property="og:title" content={selectedBlog &&selectedBlog.og_title} />
        <meta property="og:description" content={selectedBlog &&selectedBlog.og_description} />
        <meta property="og:image" content={selectedBlog &&selectedBlog.og_image} />
        <meta property="og:url" content={selectedBlog &&selectedBlog.og_url} />
        <meta property="og:type" content={selectedBlog &&selectedBlog.og_type}/>
        <meta property="og:locale" content={selectedBlog &&selectedBlog.og_locale} />
        <meta name="robots" content={selectedBlog && selectedBlog.robots}/>
      </Helmet>

      <section>
        <div className="breadCrumbWrapper">
          <div className="auto-container">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 text-left">
                    <h3 className="page_title">
                      {selectedLanguage == "English"
                        ? selectedBlog.title_en
                        : selectedBlog.title_ar}
                    </h3>
                    <p>
                      <a className="color-white" href="/">
                        Home
                      </a>
                      <span className="mx-2">
                        <i className="fas fa-angle-right"></i>
                      </span>
                      <a
                        className="color-white"
                        href="blogs"
                      >
                        Blog
                      </a>
                      <span className="mx-2">
                        <i className="fas fa-angle-right"></i>
                      </span>
                      <span className="color-white">
                        {selectedLanguage == "English"
                          ? selectedBlog.title_en
                          : selectedBlog.title_ar}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="sidebar-page-container pt-3 px-0">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="content-side col-lg-8 col-md-12 col-sm-12">
              <div className="blog-single">
                <div className="news-block">
                  <div className="inner-box">
                    <div className="image blog_image m-0">
                      <img
                        src={
                          ASSETS +
                          "public/images/blogs/large/" +
                          selectedBlog.image
                        }
                        alt={selectedBlog.image_alt}
                        title={selectedBlog.image_title}
                      />
                    </div>
                    <div className="lower-content px-3 pt-3">
                      <ul className="post-info">
                        <li>
                          <span className="far fa-calendar"></span>{formatDate(selectedBlog.post_date)}
                        </li>
                      </ul>
                      <h3>
                        {selectedLanguage == "English"
                          ? selectedBlog.title_en
                          : selectedBlog.title_ar}
                      </h3>
                      <div className="desc">
                        {parse(
                          selectedLanguage == "English"
                            ? selectedBlog.description_en || ""
                            : selectedBlog.description_ar || ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="sidebar-side col-lg-4 col-md-12 col-sm-12">
              <aside className="sidebar">
                <div className="sidebar-widget latest-news">
                  <div className="sidebar-title">
                    <h3>Popular Posts</h3>
                  </div>
                  <div className="widget-content">
                    {popularBlogs &&
                      popularBlogs.map((singleBlog, singBlogIndex) => (
                        <article className="post" key={singBlogIndex}>
                          <div className="post-thumb">
                            <Link to={"/blogs/" + singleBlog.blog_title_slug}>
                              <img
                                src={
                                  ASSETS +
                                  "public/images/blogs/large/" +
                                  singleBlog.image
                                }
                                alt={singleBlog.image_alt}
                                title={singleBlog.image_title}
                              />
                            </Link>
                          </div>
                          <h5>
                            <Link to={"/blogs/" + singleBlog.blog_title_slug}>
                              {selectedLanguage == "English"
                                ? singleBlog.title_en
                                : singleBlog.title_ar}
                            </Link>
                          </h5>
                          <div className="post-info">{formatDate(singleBlog.post_date)}</div>
                        </article>
                      ))}
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
