import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { submitLanding } from "../redux/features/customer-requests/contactusSlice";
import { API_URL, ASSETS, SITE_KEY } from "../config/Constants";
import axios from "axios";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { getLandingBanners } from "../redux/features/cms/bannerSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { LanguageContext } from "../components/LanguageProvider";
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import $ from 'jquery';
import 'select2/dist/css/select2.min.css';
import 'select2';

export default function Landing_page() {
  const [banners, setBanners] = useState(false);
  const location = useLocation();
  const pathSegments = location.pathname
    .split("/")
    .filter((segment) => segment !== "");
  const language = pathSegments[1];
  const campaign = pathSegments[2];
  const source = pathSegments[3];
  const { selectedLanguage, changeLanguage } = useContext(LanguageContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    location: "NA",
    service: [],
    source,
    campaign,
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      var url_lang = 'Arabic';
      if (language === 'en') {
        url_lang = 'English';
      }
      changeLanguage(url_lang);

      try {
        const bannersData = await dispatch(getLandingBanners());
        if (bannersData.payload && bannersData.payload.data) {
          setBanners(bannersData.payload.data.lead_iamges);
        }
      } catch (error) {
        console.error("Error fetching banners:", error);
      }
    };

    fetchData();
  }, [dispatch, language, changeLanguage]);

  useEffect(() => {
    $('#service').select2({
      placeholder: selectedLanguage === "English" ? "SELECT SERVICE" : "قم بإختيار الخدمة",
      allowClear: true,
      multiple: true,
      width: '100%' 
    }).on('change', function() {
      const selectedValues = $(this).val();
      setFormData({ ...formData, service: selectedValues });
    });

    // Cleanup the Select2 instance on component unmount
    return () => {
      $('#service').select2('destroy');
    };
  }, [selectedLanguage, formData]);

  const onChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const onsubmitContact = async () => {
    setIsLoading(true);

    dispatch(submitLanding(formData)).then((res) => {
      if (res.payload.success) {
        navigate("/thankyou");
      } else {
        setIsLoading(false);
      }
    });
  };

  return (
    <>
      <Helmet>
        <title>Auto Fix - Periodic Maintenance & General Car Maintenance</title>
        <meta
          name="title"
          content="Car Service, Periodic Maintenance, General Car Service & Car Maintenance for all models. Electrical repair. Cleaning service. Car Service Detailing etc."
        />
        <link
          rel="canonical"
          href="https://autofix.bh/landing-page/website/website"
        />
        <meta
          name="description"
          content="Car Service, Periodic Maintenance, General Car Service & Car Maintenance for all models. Electrical repair. Cleaning service. Car Service Detailing etc."
        />
      </Helmet>

      <section className="contact-section-two pt-4 pb-0" dir={selectedLanguage !== "English" ? 'rtl' : 'ltr'}>
        <div className="auto-container pt-4">
          <div className="inner-container">
            {banners && banners.length > 0 && selectedLanguage === "English" ? (
              <img
                src={`${banners[0].image_path}/${banners[0].image}`}
                alt="Values"
                className="img-fluid"
              />
            ) : banners && banners.length > 1 ? (
              <img
                src={`${banners[1].image_path}/${banners[1].image}`}
                alt="Values"
                className="img-fluid"
              />
            ) : (
              <div className="text-center">No banner available</div>
            )}

            <div className="sec-title text-center mb-3 mt-5">
              <h4>{selectedLanguage === "English" ? "Looking for the best auto care experience in BAHRAIN?" : "هل تبحث عن أفضل مقدم خدمات العناية بالمركبات في البحرين؟"}</h4>
            </div>

            <div className="contact-form-two mt-4 px-3 pt-3 col-md-8 mx-auto mb-4">
              <form>
                <div className="row clearfix">
                  <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                    <input
                      type="text"
                      name="name"
                      id="id"
                      value={formData.name}
                      placeholder={selectedLanguage === "English" ? "Name" : "الإسم"}
                      onChange={onChange}
                      required
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 form-group contactus">
                    <PhoneInput
                      country={'bh'}
                      value={formData.phone}
                      onChange={(value) => setFormData({ ...formData, phone: value })}
                      inputProps={{
                        name: 'phone',
                        id: 'phone',
                        required: true,
                        autoFocus: true,
                        className: 'inp_field phone-input phone-input-contactus'
                      }}
                      placeholder={selectedLanguage === "English" ? "Phone" : "الهاتف"}
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                    <input
                      type="email"
                      name="email"
                      onChange={onChange}
                      value={formData.email}
                      placeholder={selectedLanguage === "English" ? "Email" : "البريد الإلكتروني"}
                      required
                    />
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                    <select
                      className="form-control select2"
                      name="service"
                      id="service"
                      multiple
                    >
                      <option value="">
                        {selectedLanguage === "English" ? "SELECT SERVICE" : "قم بإختيار الخدمة"}
                      </option>
                      <option value="currentOffer">
                        {selectedLanguage === "English" ? "Current Offer" : "Current Offer"}
                      </option>
                      <option value="completeCareService">
                        {selectedLanguage === "English" ? "Complete Care Service" : "خدمة صيانة شاملة"}
                      </option>
                      <option value="premiumCarService">
                        {selectedLanguage === "English" ? "Premium Car Service" : "خدة صيانة بريميوم"}
                      </option>
                      <option value="expressService">
                        {selectedLanguage === "English" ? "Express Service" : "خدمة صيانة سريعة"}
                      </option>
                      <option value="freeVehicleInspection">
                        {selectedLanguage === "English" ? "Free Vehicle Inspection" : "فحص مجاني للمركبة"}
                      </option>
                    </select>
                  </div>

                  <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                    <textarea
                      className="form-control"
                      type="text"
                      name="message"
                      id="message"
                      placeholder={selectedLanguage === "English" ? "Message" : "رسالة"}
                      onChange={onChange}
                      rows="3"
                    ></textarea>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                    {isLoading ? (
                      <div className="text-center">
                        <img
                          className="mx-auto"
                          src="/assets/front/loader/loader.gif"
                          alt="Loader"
                          title="loading..."
                          style={{ height: "50px" }}
                        />
                      </div>
                    ) : (
                      <div className="row">
                        <div className="text-center mx-auto">
                          <button
                            className="theme-btn btn-style-two text-center mt-4 submitFormButton"
                            type="button"
                            onClick={onsubmitContact}
                            disabled={isLoading}
                            name="submit-form"
                          >
                            <span className="btn-title">
                              {isLoading ? "Submitting ....." : selectedLanguage === "English" ? "Submit" : "إرسال"}
                            </span>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
