import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Loader from "../components/loader/Loader";
import { Link } from "react-router-dom";
import { getBlog } from "../redux/features/cms/blogSlice";
import { ASSETS } from "../config/Constants";
import { LanguageContext } from "../components/LanguageProvider";
import { Helmet } from 'react-helmet';

export default function Blogs() {
  const [blogs, setBlogs] = useState([]);
  const [seo, setSeo] = useState();
  const [pageNumber, setPageNumber] = useState(0); // Current page number
  const [isLoading, setIsLoading] = useState(true); // Loading indicator

  const dispatch = useDispatch();
  const { selectedLanguage } = useContext(LanguageContext);

  useEffect(() => {
    dispatch(getBlog())
      .then((res) => {
        setBlogs(res.payload.data.blogs);
        setSeo(res.payload.data.seo);
        setIsLoading(false); 
      })
      .catch(() => setIsLoading(false)); // Handle loading error
  }, [dispatch]);

  // Calculate the index range for the current page
  const blogsPerPage = 9; // Number of blogs per page
  const indexOfLastBlog = (pageNumber + 1) * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = blogs.slice(indexOfFirstBlog, indexOfLastBlog);

  // Handle page change
  const handlePageChange = (selected) => {
    setPageNumber(selected);
    window.scrollTo(0, 0);
  };

  if (isLoading) {
    return <Loader />;
  }


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };



  return (
    <>
    
      <Helmet>
        <title>{seo &&seo.meta_title}</title>
        <meta name="title" content={seo &&seo.meta_title}/>
        <link rel="canonical" href={seo &&seo.canonical_url} />
        <meta name="description" content={seo && seo.meta_description} />
        <meta name="keywords" content={seo && seo.meta_keywords} />
      </Helmet>

      <section>
        <div className="breadCrumbWrapper">
          <div className="auto-container">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 text-left">
                   
                   <h3 className="page_title">{selectedLanguage == "English" ?'Blogs':'المدونات'}</h3>

                    <p>
                      <span>{selectedLanguage == "English" ?'Home':'الصفحة الرئيسية'}</span>
                      <span className="mx-2">
                        <i className="fas fa-angle-right"></i>
                      </span>
                      <span>{selectedLanguage == "English" ?'Blogs':'المدونات'}</span>

                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="news-section">
        <div className="auto-container">
          <div className="row">
            {currentBlogs.map((blog, blogIndex) => (
              <div className="col-lg-4 col-md-6 col-sm-12" key={blogIndex}>
                <div className="news-block">
                  <div className="inner-box">
                    <div className="image">
                      <Link to={"/blogs/" + blog.blog_title_slug}>
                        <img
                          src={
                            ASSETS + "public/images/blogs/large/" + blog.image
                          }
                          alt={blog.image_alt}
                          title={blog.image_title}
                          loading="lazy" // Lazy loading for images
                        />
                      </Link>
                    </div>
                    <div className="lower-content">
                      <ul className="post-info">
                        <li>
                          <span className="far fa-calendar"></span>{formatDate(blog.post_date)}
                        </li>
                      </ul>
                      <h3>
                        <Link to={"/blogs/" + blog.blog_title_slug}>
                          {selectedLanguage == "English"
                            ? blog.title_en
                            : blog.title_ar}
                        </Link>
                      </h3>
                      <div className="text">
                        {selectedLanguage == "English"
                          ? blog.short_description_en
                          : blog.short_description_ar}
                      </div>
                      <Link
                        to={"/blogs/" + blog.blog_title_slug}
                        className="read-more"
                      >
                        Read More
                        <span className="fa fa-long-arrow-alt-right"></span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/* Pagination */}
          <ul className="pagination">
            {Array.from({ length: Math.ceil(blogs.length / blogsPerPage) }).map(
              (_, index) => (
                <li
                  key={index}
                  className={`page-item ${
                    pageNumber === index ? "active" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(index)}
                  >
                    {index + 1}
                  </button>
                </li>
              )
            )}
          </ul>
        </div>
      </section>
    </>
  );
}
